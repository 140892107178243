import React from 'react'
import { AutoColumn } from '../Column'
import { RowBetween, RowBlank } from '../Row'
import styled from 'styled-components'
import CurrencyLogo from 'components/CurrencyLogo'
import { TYPE, StyledInternalLink } from '../../theme'
import DoubleCurrencyLogo from '../DoubleLogo'
import { ETHER, JSBI, TokenAmount } from '@uniswap/sdk'
import { ButtonPrimaryTight } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
import { useColor } from '../../hooks/useColor'
import { currencyId } from '../../utils/currencyId'
// import { unwrappedToken } from '../../utils/wrappedCurrency'
import { useTotalSupply } from '../../data/TotalSupply'
// import { GetRewardTokenPrice } from '../../data/RewardTokenPrice'
import { usePair } from '../../data/Reserves'
import useUSDCPrice from '../../utils/useUSDCPrice'
// import { BIG_INT_SECONDS_IN_WEEK } from '../../constants'

import BackgroundLogo from '../../assets/images/NFTtest.png'
import { PoolShareCard } from '../Card'

import { useCurrency } from '../../hooks/Tokens'



const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.25rem;
  margin-bottom: 0rem;
  margin-right: 0rem;
  margin-left: 0rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`

// lower card Pool Farm and Stake pages - solid bg 04-08-2022
const Wrapper = styled(AutoColumn) <{ showBackground: boolean; bgColor: any }>`
  border-radius: 2.0rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '1')};
//  background: radial-gradient(91.85% 100% at 1.84% 0%,rgba(13,140,165,0.2) 0%,#314d58 100%);
//  background: linear-gradient(235deg, ${({ theme }) => theme.bg11}, ${({ theme }) => theme.bg21});
  background-color: ${({ theme }) => theme.bg9};

  
  color: ${({ theme, showBackground }) => (showBackground ? theme.white : theme.text1)} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem 1.5rem 0.5rem 1.5rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`

//const BreakBlue = styled(Break)`
//  width: 100%;
//  height: 1px;
//  margin-top: 0rem;
//  background-color: ${({ theme }) => theme.bg2};
//`

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 0.5rem 0rem 0.5rem 0rem;
  opacity: ${({ showBackground }) => (showBackground ? '1' : '0.4')};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`

export default function PoolCard({ stakingInfo }: { stakingInfo: StakingInfo }) {
  const token0 = stakingInfo.tokens[0]
  const token1 = stakingInfo.tokens[1]
  const currency2 = stakingInfo.baseToken[0]


  const currency0 = token0
  const currency1 = token1

  const currencyA = useCurrency('0xBf70eA7470A43ac3149FCAB16F837311151c7983');



  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan('0'))


  // get the color of the token
  const token = currency0 === ETHER ? token1 : token0
  const WETH = currency0 === ETHER ? token0 : token1
  const backgroundColor = useColor(token)

  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo.stakedAmount.token)
  const [, stakingTokenPair] = usePair(...stakingInfo.tokens)



  // let returnOverMonth: Percent = new Percent('0')
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  if (totalSupplyOfStakingToken && stakingTokenPair && !stakingInfo.isTokenOnly) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      token0,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(token0).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
    // const ddd = JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw);

  }
  else if (stakingInfo.isTokenOnly) {
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      token0,
      stakingInfo.totalStakedAmount.raw
    )
  }




  var show = isStaking || !stakingInfo.ended;
  // get the USD value of staked WETH
  const USDPrice = useUSDCPrice(WETH)
  const rewardTokenPrice = useUSDCPrice(token0)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH && (stakingInfo.isTokenOnly ? rewardTokenPrice : USDPrice)?.quote(valueOfTotalStakedAmountInWETH)
  var valueofApy: string | undefined
  if (rewardTokenPrice !== undefined && valueOfTotalStakedAmountInUSDC !== undefined && !valueOfTotalStakedAmountInUSDC?.equalTo(JSBI.BigInt(0))) {
    valueofApy = (stakingInfo.totalRewardRate?.multiply(`${60 * 60 * 24 * 365}`)
      ?.divide(`${10000}`)
      ?.multiply(`${Math.ceil(parseFloat(rewardTokenPrice.toFixed(4)) * 1000000)}`)
      ?.multiply(`${1000000}`)
      ?.divide(`${valueOfTotalStakedAmountInUSDC?.raw}`)
      ?.toFixed(4).toString()
    )
  } else {
    if (stakingInfo.isTokenOnly) {
      valueofApy = stakingInfo.totalRewardRate?.multiply(`${1.009939602 * 60 * 60 * 24 * 365 * (10 ** 6)}`)?.toFixed(4).toString();
    } else {
      if (stakingTokenPair && totalSupplyOfStakingToken) {
        valueofApy = stakingInfo.totalRewardRate?.multiply(`${60 * 60 * 24 * 365}`)?.divide(JSBI.divide(
          JSBI.multiply(
            JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(token0).raw),
            JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
          ),
          totalSupplyOfStakingToken.raw
        ))?.multiply(`${100000000000000000000}`)?.toFixed(4).toString();
      }
    }
  }

  const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  width:200px;
  & > img,
  span {
    height: ${({ size }) => (size ? size + 'px' : '180px')};
    width: ${({ size }) => (size ? size + 'px' : '200px')};
    border-radius:10px;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-items: flex-end;
  `};
`
  return (
    show ?
      <Wrapper showBackground={isStaking} bgColor={backgroundColor}>

        <TopSection style={{ alignItems: 'center' }}>
          {
            stakingInfo.isNftToken ?
              <>
                {/* <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} /> */}
                <IconWrapper>
                  <img src={BackgroundLogo} alt={'wallet connect logo'} />
                </IconWrapper>
                <TYPE.titleText4 fontWeight={500} fontSize={24} style={{ textAlign: 'center', marginLeft: '60px' }}>
                  {'NFT'} ({currency0.symbol})
                </TYPE.titleText4>
              </>
              :
              !stakingInfo.isTokenOnly && !stakingInfo.isNftToken ?
                stakingInfo.name === 'ABC-WOHO' ?
                  <>
                    <DoubleCurrencyLogo currency0={currency0} currency1={currencyA ?? undefined} size={24} />
                    <TYPE.titleText4 fontWeight={500} fontSize={24} style={{ marginLeft: '8px' }}>
                      {currency0.symbol}-{currency1.symbol}
                    </TYPE.titleText4>
                  </>
                  :
                  <>
                    <DoubleCurrencyLogo currency0={currency0} currency1={currency2} size={24} />
                    <TYPE.titleText6 fontWeight={500} fontSize={24} style={{ marginLeft: '8px' }}>
                      {stakingInfo.name}
                    </TYPE.titleText6>
                  </>
                :
                stakingInfo.name === 'WOHO' ?
                  <>
                    <DoubleCurrencyLogo currency0={currencyA ?? undefined} size={24} />
                    <TYPE.titleText6 fontWeight={500} fontSize={24} style={{ marginLeft: '-12px', textAlign: 'left' }}>
                      {currency0.symbol} {'Staking'}
                    </TYPE.titleText6>
                  </>
                  :
                  <>
                    <CurrencyLogo currency={currencyA ?? undefined} size={'24px'} />
                    <TYPE.titleText6 fontWeight={500} fontSize={24} style={{ marginLeft: '-12px', textAlign: 'left' }}>
                      {currency0.symbol}
                    </TYPE.titleText6>
                  </>
          }
          <StyledInternalLink to={`/OHO/${currencyId(currency0)}/${currencyId(currency1)}/${stakingInfo.stakingRewardAddress}`} style={{ width: '100%' }}>
            <ButtonPrimaryTight padding="8px" borderRadius="8px">
              {isStaking ? 'Manage' : 'Deposit'}
            </ButtonPrimaryTight>
          </StyledInternalLink>

        </TopSection>


        <PoolShareCard margin={'0rem 1rem 1rem 1rem'} padding={'0.75rem 1rem 0.25rem 1rem'} borderRadius={'1rem'}>

          {stakingInfo.isNftToken ?
            <StatContainer style={{ width: '100%', position: 'absolute', left: '210px', top: '100px' }}>
              <RowBlank>
                <TYPE.titleText3> Total deposited</TYPE.titleText3>
                <TYPE.titleText3>
                  {valueOfTotalStakedAmountInUSDC
                    ? Math.ceil(parseFloat(valueOfTotalStakedAmountInUSDC.toFixed(4)) * (10 ** 6)) / (10 ** 6) !== 0
                      ? `$${valueOfTotalStakedAmountInUSDC.toFixed(4, { groupSeparator: ',' })}`
                      : `$${valueOfTotalStakedAmountInUSDC.toFixed(4, { groupSeparator: ',' })}`
                    : `${valueOfTotalStakedAmountInWETH?.toSignificant(0, { groupSeparator: ',' }) ?? '-'} WOHO`}
                </TYPE.titleText3>
              </RowBlank>
              <RowBetween>
                <TYPE.titleText3> Pool Rate </TYPE.titleText3>
                <TYPE.titleText3>{`${stakingInfo.totalRewardRate
                  ?.multiply(`${60 * 60 * 24}`)
                  ?.toFixed(8, { groupSeparator: ',' })} WOHO/day`}</TYPE.titleText3>
              </RowBetween>
              <RowBetween>
                <TYPE.titleText3> APY </TYPE.titleText3>
                <TYPE.titleText3>
                  {valueofApy !== undefined
                    ? valueofApy !== '0.000'
                      ? `${parseFloat(valueofApy).toFixed(4)} %`
                      : `0 %`
                    : `---`}
                </TYPE.titleText3>
              </RowBetween>
            </StatContainer>
            :
            <></>}


          {!stakingInfo.isNftToken ?

            stakingInfo.isTokenOnly ?
              <StatContainer>
                <RowBetween>
                  <TYPE.titleText3> Total Deposited</TYPE.titleText3>
                  <TYPE.titleText3>
                    {valueOfTotalStakedAmountInUSDC
                      ? Math.ceil(parseFloat(valueOfTotalStakedAmountInUSDC.toFixed(4)) * (10 ** 6)) / (10 ** 6) !== 0
                        ? `$${valueOfTotalStakedAmountInUSDC.toFixed(4, { groupSeparator: ',' })}`
                        : `$${valueOfTotalStakedAmountInUSDC.toFixed(4, { groupSeparator: ',' })}`
                      : `${valueOfTotalStakedAmountInWETH?.toFixed(4, { groupSeparator: ',' }) ?? '-'} WOHO`}
                  </TYPE.titleText3>
                </RowBetween>
                <RowBetween>

                  <TYPE.titleText3> Pool Rate </TYPE.titleText3>
                  <TYPE.titleText3>{`${stakingInfo.totalRewardRate
                    ?.multiply(stakingInfo.totalStakedAmount)
                    ?.multiply(`${60 * 60 * 24 * (10 ** 4)}`)
                    // ?.multiply(`${stakingInfo.totalStakedAmount.toFixed(18)}`)
                    ?.toFixed(4, { groupSeparator: ',' })} WOHO / day`}</TYPE.titleText3>
                </RowBetween>
                <RowBetween>
                  <TYPE.titleText3> APY </TYPE.titleText3>
                  <TYPE.titleText3>
                    {valueofApy !== undefined
                      ? valueofApy !== '0.000'
                        ? `${parseFloat(valueofApy).toFixed(4)} %`
                        : `0 %`
                      : `---`}
                  </TYPE.titleText3>
                </RowBetween>
              </StatContainer>
              :
              <StatContainer>
                <RowBetween>
                  <TYPE.titleText3> Total Deposited</TYPE.titleText3>
                  <TYPE.titleText3>
                    {valueOfTotalStakedAmountInUSDC
                      ? Math.ceil(parseFloat(valueOfTotalStakedAmountInUSDC.toFixed(4)) * 1000000) / 1000000 !== 0
                        ? `$${valueOfTotalStakedAmountInUSDC.toFixed(4, { groupSeparator: ',' })}`
                        : `$${valueOfTotalStakedAmountInUSDC.toFixed(4, { groupSeparator: ',' })}`
                      : `${valueOfTotalStakedAmountInWETH?.toFixed(4, { groupSeparator: ',' }) ?? '-'} ABC`}
                  </TYPE.titleText3>
                </RowBetween>
                <RowBetween>

                  <TYPE.titleText3> Pool Rate </TYPE.titleText3>
                  <TYPE.titleText3>{`${stakingInfo.totalRewardRate
                    ?.multiply(`${60 * 60 * 24}`)
                    ?.toFixed(1, { groupSeparator: ',' })} ABC / day`}</TYPE.titleText3>
                </RowBetween>
                <RowBetween>
                  <TYPE.titleText3> APY </TYPE.titleText3>
                  <TYPE.titleText3>
                    {valueofApy !== undefined
                      ? valueofApy !== '0.000'
                        ? `${parseFloat(valueofApy).toFixed(4)} %`
                        : `0 %`
                      : `---`}
                  </TYPE.titleText3>
                </RowBetween>
              </StatContainer>
            :
            <></>
          }
          {isStaking && (
            <>
              <BottomSection showBackground={true}>
                <TYPE.titleText6 fontWeight={600} fontSize={20} letterSpacing={'2px'}>
                  <span>Your Rate</span>
                </TYPE.titleText6>

                <TYPE.titleText6 style={{ textAlign: 'right' }} fontWeight={600} fontSize={20} letterSpacing={'2px'}>
                  <span role="img" aria-label="wizard-icon" style={{ marginRight: '0.125rem' }}>
                    ⚡
                  </span>
                  {!stakingInfo.isTokenOnly
                    ?
                    `${stakingInfo.rewardRate
                      ?.multiply(`${60 * 60 * 24}`)
                      // ?.multiply(stakingInfo.balances)
                      ?.toSignificant(4, { groupSeparator: ',' })} ABC / day`
                    : `${(stakingInfo.totalRewardRate)?.multiply(`${60 * 60 * 24 * 10 ** 4}`)?.multiply(stakingInfo.stakedAmount)?.toFixed(4, { groupSeparator: ',' })} WOHO / DAY`
                  }
                </TYPE.titleText6>
              </BottomSection>
            </>
          )}

        </PoolShareCard>
      </Wrapper> : <span style={{ width: 0, display: "none" }}></span>
  )
}
