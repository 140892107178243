import React, { useContext } from 'react'
import { AutoColumn } from '../../components/Column'
import styled, { ThemeContext } from 'styled-components'
import { STAKING_REWARDS_INFO, useStakingInfo } from '../../state/stake/hooks'
import { TYPE } from '../../theme'
import PoolCard from '../../components/earn/PoolCard'
import { RowBlank, RowBetween } from '../../components/Row'
import { CardSection, DataCard } from '../../components/earn/styled'
// import { Countdown } from './Countdown'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { Gift } from 'react-feather'
import { BottomCardWide } from '../../components/Card'
// import { JSBI } from '@uniswap/sdk'
// import { BIG_INT_ZERO } from '../../constants'
// import { OutlineCard } from '../../components/Card'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

const FixedHeightRow = styled(RowBetween)`
  height: 1.25rem;
`

const DataRow = styled(RowBlank)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
flex-direction: column;
`};
`

export default function Earn() {
  const theme = useContext(ThemeContext)
  const { chainId } = useActiveWeb3React()
  // console.log("chainid", chainId);
  // staking info for connected account
  const stakingInfos = useStakingInfo()

  /**
   * only show staking cards with balance
   * @todo only account for this if rewards are inactive
   */
  // const stakingInfosWithBalance = stakingInfos?.filter(s => JSBI.greaterThan(s.stakedAmount.raw, BIG_INT_ZERO))

  // toggle copy if rewards are inactive
  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)
  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="0">
        <DataCard>
          <CardSection>
            <AutoColumn gap="md">
              <RowBlank>
                <TYPE.titleText4
                  fontWeight={500}
                  fontSize={24}
                  color={'text1'}
                  letterSpacing={'1px'}
                  textAlign={'center'}
                >
                  Liquidity Mining
                </TYPE.titleText4>
              </RowBlank>
              <RowBlank>
                <TYPE.titleText3 fontWeight={500} fontSize={14} textAlign={'center'} lineHeight={'1.0rem'}>
                  Deposit your LP tokens to earn rewards <Gift size="16" color={theme.bg23} />.
                </TYPE.titleText3>
              </RowBlank>{' '}
            </AutoColumn>
          </CardSection>
        </DataCard>
      </TopSection>

      <AutoColumn gap="0rem" style={{ width: '100%', maxWidth: '720px', textAlign: 'center', color: 'theme.text1' }}>
        <DataRow style={{ alignItems: 'baseline' }}>
          <TYPE.mediumHeader
            style={{
              marginTop: '0rem',
              fontSize: '24px',
              letterSpacing: '1px',
              textAlign: 'center',
              paddingBottom: '0.5rem'
            }}
          >
            Participating Pools
          </TYPE.mediumHeader>
          {/* <Countdown exactEnd={stakingInfos?.[0]?.periodFinish} /> */}
        </DataRow>
        {/* // working section staking */}
        <PoolSection>
          {stakingRewardsExist && stakingInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !stakingRewardsExist ? (
            'No active rewards'
          ) : (
            stakingInfos?.map(stakingInfo => {
              // need to sort by added liquidity here
              return !stakingInfo.isTokenOnly && !stakingInfo.isNftToken ? (
                <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
              ) : (
                <></>
              )
            })
          )}
        </PoolSection>
        {/* till here akash */}
        {/* <PoolSection>
          {stakingRewardsExist && stakingInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !stakingRewardsExist ? (
            <OutlineCard>Coming Soon</OutlineCard>
          ) : stakingInfos?.length !== 0 && stakingInfosWithBalance.length === 0 ? (
            <OutlineCard>Coming Soon</OutlineCard>
          ) : (
                  stakingInfosWithBalance?.map(stakingInfo => {
                    // need to sort by added liquidity here
                    return <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
                  })
                )}
        </PoolSection> */}

        <BottomCardWide>
          <AutoColumn gap="0px">
            <FixedHeightRow>
              <TYPE.titleText1
                color={'bg19'}
                fontSize={11}
                paddingTop={'0rem'}
                paddingBottom={'0rem'}
                letterSpacing={'0.5px'}
                textAlign={'left'}
              >
                OHO - Humanized Crypto for Everyone
              </TYPE.titleText1>
              <TYPE.titleText1
                color={'bg19'}
                fontSize={11}
                paddingTop={'0rem'}
                paddingBottom={'0rem'}
                letterSpacing={'0.5px'}
                textAlign={'right'}
              >
                All Rights Reserved.
              </TYPE.titleText1>
            </FixedHeightRow>
          </AutoColumn>
        </BottomCardWide>
      </AutoColumn>
    </PageWrapper>
  )
}
