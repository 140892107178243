import React from 'react'
import styled from 'styled-components'
import { CardProps, Text } from 'rebass'
import { Box } from 'rebass/styled-components'
import { transparentize } from 'polished'

const Card = styled(Box)<{ width?: string; padding?: string; border?: string; borderRadius?: string }>`
  width: ${({ width }) => width ?? '100%'};
  padding: 1rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`

const SlimCard = styled(Box)<{ width?: string; padding?: string; border?: string; borderRadius?: string }>`
  width: ${({ width }) => width ?? '100%'};
  padding: 0.5rem 1rem 0.5rem 1rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`

const WideCard = styled(Box)<{ padding?: string; border?: string; borderRadius?: string }>`
  padding: 0.5rem 1rem 0.5rem 1rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`

export default Card

// Inner card on Add Liquidity page - OHO Swap 04-05-2022
export const LightCard = styled(Card)`
//  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => theme.bg27};
`

export const PoolCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg21};
`

// Green card outlined - OHO Swap 04-16-2022
export const GreenCard = styled(SlimCard)`
  background-color: ${({ theme }) => theme.green2};
  border-radius: 1.5rem;
`

// Blue card outlined - OHO Swap 04-16-2022
export const BlueLineCard = styled(SlimCard)`
  border: 2px solid ${({ theme }) => transparentize(0.8, theme.text2)};
  border-radius: 1.5rem;
  background-color: ${({ theme }) => transparentize(0.95, theme.text2)};
`

export const BottomCard = styled(SlimCard)`
  z-index:100;
  margin-top: 0rem;
  padding: 0.25rem 0.5rem;
  background-color: transparent;
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
 // display: flex;
  width: 420px;
  border-radius: 1.5rem;
 // border: 1px solid ${({ theme }) => theme.bg19};
`

export const BottomCardWide = styled(SlimCard)`
  z-index: 100;
  margin-top: 0rem;
  padding: 0.25rem 0.5rem;
  background-color: transparent;
  color: ${({ theme }) => theme.text3};
  font-weight: 500;
  border-radius: 1.5rem;
`

export const RedCard = styled(SlimCard)`
  background-color: ${({ theme }) => theme.bg10};
  color: ${({ theme }) => theme.text3};
  border-radius: 1.5rem;
`

export const GoldCard = styled(SlimCard)`
  background-color: ${({ theme }) => theme.bg14};
  color: ${({ theme }) => theme.white};
  border-radius: 1.5rem;
`

// Green card outlined - OHO Swap 04-16-2022
export const GreenLineCard = styled(SlimCard)`
  border: 2px solid ${({ theme }) => transparentize(0.8, theme.green2)};
  background-color: ${({ theme }) => transparentize(0.95, theme.green2)};
  color: ${({ theme }) => theme.text3};
  border-radius: 1.5rem;
`

// Red card outlined - OHO Swap 04-16-2022
export const RedLineCard = styled(SlimCard)`
  border: 2px solid ${({ theme }) => transparentize(0.8, theme.bg10)};
  border-radius: 1.5rem;
  background-color: ${({ theme }) => transparentize(0.95, theme.bg10)};
`

// Gold card outlined - OHO Swap 04-16-2022
export const GoldLineCard = styled(SlimCard)`
  border: 2px solid ${({ theme }) => transparentize(0.8, theme.bg14)};
  border-radius: 1.5rem;
  background-color: ${({ theme }) => transparentize(0.95, theme.bg14)};
`

export const WhiteCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg11};
  border-radius: 1.5rem;
`

export const LightGreyCard = styled(SlimCard)`
  border: 1px solid ${({ theme }) => theme.text16};
  border-radius: 1.5rem;
`

export const TransparentCard = styled(SlimCard)`
  border-radius: 0rem;
`

// OHO Swap - for showing Prices and Pool Share at the bottom of the Liquidity Page
export const TextCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg21};
  color: ${({ theme }) => theme.text10};
  border-radius: 1.5rem;
  font-weight: 500;
`

// OHO Swap - for showing inside Prices and Pool Share at the bottom of the Liquidity Page
export const InsideCard = styled(SlimCard)`
  background-color: ${({ theme }) => theme.bg11};
  font-weight: 500;
`

// OHO Swap - For "Your Position" card
export const BodyCard = styled(SlimCard)`
  z-index:2;
  border-radius: 2rem;
  background-color: ${({ theme }) => theme.bg9};
//  box-shadow: 0 4px 8px 0 ${({ theme }) => transparentize(0.95, theme.shadow1)};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`

// OHO Swap - For Removing Liquidity innerbody
export const PlainCard = styled(SlimCard)`
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.bg21};
`

// OHO Swap
export const PoolInfoCard = styled(SlimCard)`
  background-color: ${({ theme }) => theme.bg11};
//  color: ${({ theme }) => theme.text2};
  border-radius: 1.5rem;
`

export const MessageCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg29};
  color: ${({ theme }) => theme.text6};
`

// OHO Swap - for Confirm Swap window
export const ConfirmSwapCard = styled(Card)`
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.bg1};
`

// OHO Swap - for Settings Modal 04-16-2022
export const SettingsCard = styled(Card)`
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.bg1};
`

// OHO Swap - for Confirm Liquidity window
export const ConfirmLiquidityCard = styled(Card)`
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.bg1};
`

// OHO Swap - for Confirm Liquidity window
export const WithdrawCard = styled(Card)`
  border-radius: 1.5rem;
  background-color: ${({ theme }) => theme.bg1};
`

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg11};
  color: ${({ theme }) => theme.text2};
`

export const OutlineCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg3};
`

export const YellowCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.yellow2};
  font-weight: 500;
`

export const PinkCard = styled(Card)`
  background-color: rgba(13, 140, 165, 0.03);
  color: ${({ theme }) => theme.primary1};
  font-weight: 500;
`

//OHO Swap 04-03-2022
export const PoolShareCard = styled(WideCard)`
  //background-color: ${({ theme }) => theme.bg21};
  color: ${({ theme }) => theme.text15};
  //border: 1px solid ${({ theme }) => theme.bg17};
  //border: 1px solid ${({ theme }) => transparentize(0.0, theme.bg12)};
  border-radius: 1.5rem;
  //background-color: ${({ theme }) => transparentize(0.8, theme.bg17)};
  background: linear-gradient(0deg, ${({ theme }) => theme.bg12}, ${({ theme }) => theme.bg21});
  //background: linear-gradient(90deg, ${({ theme }) => theme.bg12}, ${({ theme }) => theme.bg11});
  //background: radial-gradient(76.02% 75.41% at 1.84% 0%, #0d8ca5 0%, #314d58 100%);
  //background-image: ${({ theme }) =>
    `radial-gradient(76.02% 75.41% at 1.84% 0%, ${theme.bg21} 0%, ${theme.bg12} 100%)`};
`

//OHO Swap 04-20-2022
export const PoolRateCard = styled(WideCard)`
  border-radius: 1.5rem;
  background: ${({ theme }) => theme.bg9};
`

//OHO Swap 04-20-2022
export const PoolClaimCard = styled(WideCard)`
  border-radius: 1.5rem;
  background: linear-gradient(0deg, ${({ theme }) => theme.bg12}, ${({ theme }) => theme.bg21});
`

export const BlueCardStyled = styled(SlimCard)`
  background-color: ${({ theme }) => theme.bg30};
  color: ${({ theme }) => theme.text15};
  border-radius: 1.5rem;
`

export const BlueCard = ({ children, ...rest }: CardProps) => {
  return (
    <BlueCardStyled {...rest}>
      <Text fontWeight={500}>{children}</Text>
    </BlueCardStyled>
  )
}
