import { ChainId, Currency } from '@uniswap/sdk'
import React, { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import Modal from '../Modal'
import { TYPE, ExternalLink } from '../../theme'
import { Text } from 'rebass'
import { CloseIcon, CloseIconFixed, CustomLightSpinner } from '../../theme/components'
import { RowBetween, RowBlank, RowFixed } from '../Row'
import { AlertTriangle, ArrowUpCircle, CheckCircle } from 'react-feather'
import { ButtonPrimaryTight, ButtonNetwork } from '../Button'
import { AutoColumn, ColumnCenter } from '../Column'
import Circle from '../../assets/images/blue-loader.svg'
import MetaMaskLogo from '../../assets/images/metamask.png'
import { getEtherscanLink } from '../../utils'
import { useActiveWeb3React } from '../../hooks'
import useAddTokenToMetamask from 'hooks/useAddTokenToMetamask'


//OHO Swap customization 04-03-2022
const Wrapper = styled.div`
  width: 100%;
`

const Section = styled(AutoColumn)`
  padding: 1rem;
`

//OHO Swap customization 04-06-2022
const SectionBottomModal = styled(AutoColumn)`
  padding: 0.25rem 1rem 1rem 1rem;
`

//OHO Swap customization 04-06-2022
const SectionTopModal = styled(AutoColumn)`
  position: relative;
  border-radius: 1.5 rem;
  padding: 1.25rem 1rem 0.25rem 1rem;
`

//OHO Swap customization 04-05-2022
const CloseIconGold = styled(CloseIcon)`
  stroke: ${({ theme }) => theme.bg14};
  margin-right: 0.5rem; 
 
  :hover {
    stroke: ${({ theme }) => theme.bg4};
  }
 
  :focus {
    stroke: ${({ theme }) => theme.bg4};
  }

  :active {
  transform: scale(1.2,1.2);
  }
`

const BottomSection = styled(Section)`
  background-color: ${({ theme }) => theme.bg9};
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
`

const ConfirmedIcon = styled(ColumnCenter)`
  padding: 24px 0;
`

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`

function ConfirmationPendingContent({ onDismiss, pendingText }: { onDismiss: () => void; pendingText: string }) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIconGold onClick={onDismiss} />
        </RowBetween>
        <ConfirmedIcon>
          <CustomLightSpinner src={Circle} alt="loader" size={'90px'} />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={'center'}>
          <Text fontWeight={500} letterSpacing={'1px'} fontSize={20} color={'text1'}>
            Waiting For Confirmation
          </Text>
          <AutoColumn gap="12px" justify={'center'}>

              <TYPE.titleText1 fontSize={16} color={'text2'} textAlign={'center'}>
              {pendingText}
              </TYPE.titleText1>

          </AutoColumn>
          <Text fontSize={12} color={'text2'} textAlign="center">
            Confirm this transaction in your wallet.
          </Text>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd
}: {
  onDismiss: () => void
  hash: string | undefined
  chainId: ChainId
  currencyToAdd?: Currency | undefined
}) {
  const theme = useContext(ThemeContext)

  const { library } = useActiveWeb3React()

  const { addToken, success } = useAddTokenToMetamask(currencyToAdd)

  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <div />
          <CloseIconGold onClick={onDismiss} color={theme.primary1}/>
        </RowBetween>
        <ConfirmedIcon>
          <ArrowUpCircle strokeWidth={0.5} size={90} color={theme.text2} />
        </ConfirmedIcon>
        <AutoColumn gap="0.5rem" justify={'center'}>
          <Text fontWeight={500} fontSize={20}>
            Transaction Submitted
          </Text>
          {chainId && hash && (
            <ExternalLink href={getEtherscanLink(chainId, hash, 'transaction')}>
              <Text fontWeight={500} fontSize={14} color={theme.primary1}>
                View on OHO Scan
              </Text>
            </ExternalLink>
          )}
          {currencyToAdd && library?.provider?.isMetaMask && (
            <ButtonNetwork mt="12px" padding="8px 12px" onClick={addToken}>
              {!success ? (
                <RowFixed>
                  Add {currencyToAdd.symbol === 'OHO LP' ? 'OHO LP' : currencyToAdd.symbol} to MetaMask <StyledLogo src={MetaMaskLogo} />
                </RowFixed>
              ) : (
                  <RowFixed>
                    Added {currencyToAdd.symbol}{' '}
                    <CheckCircle size={'16px'} stroke={theme.green1} style={{ marginLeft: '6px' }} />
                  </RowFixed>
                )}
            </ButtonNetwork>
          )}
          <ButtonPrimaryTight onClick={onDismiss} style={{ margin: '0 0 0 0' }}>
            <Text fontWeight={500} fontSize={16}>
              Close
            </Text>
          </ButtonPrimaryTight>
        </AutoColumn>
      </Section>
    </Wrapper>
  )
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent
}: {
  title: string
  onDismiss: () => void
  topContent: () => React.ReactNode
  bottomContent: () => React.ReactNode
}) {
  return (
    <Wrapper >
      <SectionTopModal>
      <CloseIconFixed onClick={onDismiss}/>
        <RowBlank>
          <Text fontWeight={500} fontSize={24} paddingLeft={'0rem'} letterSpacing={'1px'} textAlign={'center'}>
            {title}
          </Text>
          </RowBlank>
        {topContent()}
      </SectionTopModal>
      <SectionBottomModal gap="0.25rem">{bottomContent()}</SectionBottomModal> 
    </Wrapper>
  )
}

export function TransactionErrorContent({ message, onDismiss }: { message: string; onDismiss: () => void }) {
  const theme = useContext(ThemeContext)
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            Error
          </Text>
          <CloseIconGold onClick={onDismiss} />
        </RowBetween>
        <AutoColumn style={{ marginTop: 20, padding: '2rem 0' }} gap="24px" justify="center">
          <AlertTriangle color={theme.red1} style={{ strokeWidth: 1.5 }} size={64} />
          <Text fontWeight={500} fontSize={16} color={theme.red1} style={{ textAlign: 'center', width: '85%' }}>
            {message}
          </Text>
        </AutoColumn>
      </Section>
      <BottomSection gap="0.25rem" > 
        <ButtonPrimaryTight onClick={onDismiss}>Dismiss</ButtonPrimaryTight>
      </BottomSection>
    </Wrapper>
  )
}

interface ConfirmationModalProps {
  isOpen: boolean
  onDismiss: () => void
  hash: string | undefined
  content: () => React.ReactNode
  attemptingTxn: boolean
  pendingText: string
  currencyToAdd?: Currency | undefined
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd
}: ConfirmationModalProps) {
  const { chainId } = useActiveWeb3React()

  if (!chainId) return null

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {attemptingTxn ? (
        <ConfirmationPendingContent onDismiss={onDismiss} pendingText={pendingText} />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
        />
      ) : (
            content()
          )}
    </Modal>
  )
}
