import React from 'react'
import styled from 'styled-components'

const ToggleElement = styled.span<{ isActive?: boolean; isOnSwitch?: boolean }>`
  padding: 0.1rem 0.5rem;
  border-radius: 2rem;
  font-size: 0.8rem;
  font-weight: 500;
  background: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.bg13 : theme.bg22) : theme.bg11)};
  color: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.text3 : theme.text3) : theme.text7)};
  border: 2px solid ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.bg11 : theme.bg11) : theme.bg11)};
  font-size: 1rem;
  font-weight: ${({ isOnSwitch }) => (isOnSwitch ? '500' : '500')};
  :hover {
    user-select: ${({ isOnSwitch }) => (isOnSwitch ? 'none' : 'initial')};
    background: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.bg13 : theme.bg22) : 'none')};
    color: ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.text3 : theme.text3) : theme.bg4)};
    //border: 2px solid ${({ theme, isActive, isOnSwitch }) => (isActive ? (isOnSwitch ? theme.bg5 : theme.bg5) : theme.bg5)};
  }
`
// toggle buttons
const StyledToggle = styled.button<{ isActive?: boolean; activeElement?: boolean }>`
  border-radius: 2rem;
  background: ${({ theme }) => theme.bg11};
  display: flex;
  //color: ${({ theme }) => theme.text12};
  border: 2px solid ${({ theme }) => theme.text7};
  width: fit-content;
  cursor: pointer;
  outline: none;
  padding: 1px;
`

export interface ToggleProps {
  id?: string
  isActive: boolean
  toggle: () => void
}

export default function Toggle({ id, isActive, toggle }: ToggleProps) {
  return (
    <StyledToggle id={id} isActive={isActive} onClick={toggle}>
      <ToggleElement isActive={isActive} isOnSwitch={true}>
        On
      </ToggleElement>
      <ToggleElement isActive={!isActive} isOnSwitch={false}>
        Off
      </ToggleElement>
    </StyledToggle>
  )
}
