import React, { useContext } from 'react'
import { AutoColumn } from '../../components/Column'
import styled, { ThemeContext } from 'styled-components'
import { STAKING_REWARDS_INFO, useStakingInfo } from '../../state/stake/hooks'
import { TYPE } from '../../theme'
import PoolCard from '../../components/earn/PoolCard'
import { RowBlank, RowBetween } from '../../components/Row'
import { CardSection, DataCard } from '../../components/earn/styled'
import { Countdown } from '../Earn/Countdown'
import Loader from '../../components/Loader'
import { useActiveWeb3React } from '../../hooks'
import { Gift } from 'react-feather'
import { BottomCardWide } from '../../components/Card'
// import { JSBI } from '@uniswap/sdk'
// import { BIG_INT_ZERO } from '../../constants'
//import { OutlineCard } from '../../components/Card'
// import { ButtonPrimary } from 'components/Button'
// import { useHarvestContract } from '../../hooks/useContract'

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`
// const PoolData = styled(DataCard)`
//   background: none;
//   border: 1px solid ${({ theme }) => theme.bg4};
//   padding: 1rem;
//   z-index: 1;
// `

const FixedHeightRow = styled(RowBetween)`
  height: 1.25rem;
`

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`

const DataRow = styled(RowBlank)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
flex-direction: column;
`};
`

export default function Earn() {
  const theme = useContext(ThemeContext)
  const { chainId } = useActiveWeb3React()
  const stakingInfos = useStakingInfo()
  /**
   * only show staking cards with balance
   * @todo only account for this if rewards are inactive
   */
  // const stakingInfosWithBalance = stakingInfos?.filter(s => JSBI.greaterThan(s.stakedAmount.raw, BIG_INT_ZERO))

  // toggle copy if rewards are inactive
  const stakingRewardsExist = Boolean(typeof chainId === 'number' && (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0)

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md" justify="center">
        {/* <PoolData style={{ width: '55%', float: 'right' }}>
                    <TYPE.body style={{ margin: 0, }}>Auto WOHO Bounty</TYPE.body>
                    {
                      stakingInfos[0]?.harvestCallFee?.toNumber() / 10 ** 18 === 0 || stakingInfos[0]?.harvestCallFee?.toNumber() === undefined ? (
                        <>
                            <br />
                            <TYPE.body fontSize={22} fontWeight={300} >
                                <TYPE.small fontSize={20} fontWeight={300} width="70%" style={{ float: 'left' }} >0</TYPE.small>
                                WOHO
                                <ButtonPrimary fontSize={14} padding="8px" style={{ float: 'right', margin: '5px' }} height='30px' width="90px" borderRadius="8px" disabled>
                                    {'Claim'}
                                </ButtonPrimary>
                            </TYPE.body>
     
                        </>
                    ) :
                        <>
                            <br />
                            <TYPE.body fontSize={22} fontWeight={300} >
                                <TYPE.small fontSize={20} fontWeight={300} width="70%" style={{ float: 'left' }} >{stakingInfos[0]?.harvestCallFee?.toNumber() / 10 ** 18}</TYPE.small>
                                WOHO
                                <ButtonPrimary fontSize={14} padding="8px" style={{ float: 'right', margin: '5px' }} height='30px' width="90px" onClick={onHarvest} borderRadius="8px">
                                    {'Claim'}
                                </ButtonPrimary>
                            </TYPE.body>
                        </>
                    }
                </PoolData> */}
        <DataCard>
          <CardSection>
            <AutoColumn gap="md">
              <RowBlank>
                <TYPE.bodyText2
                  fontWeight={500}
                  fontSize={24}
                  color={'text1'}
                  textAlign={'center'}
                  letterSpacing={'2px'}
                >
                  Staking
                </TYPE.bodyText2>
              </RowBlank>
              <RowBlank>
                <TYPE.titleText3 fontWeight={500} fontSize={14} textAlign={'center'} lineHeight={'1.0rem'}>
                  Deposit your crypto assets to earn staking rewards <Gift size="16" color={theme.bg23} />.
                </TYPE.titleText3>
              </RowBlank>{' '}
            </AutoColumn>
          </CardSection>
        </DataCard>
      </TopSection>

      <AutoColumn
        gap="0rem"
        style={{ width: '100%', maxWidth: '720px', fontSize: '20px', textAlign: 'center', color: 'theme.text1' }}
      >
        <DataRow style={{ alignItems: 'baseline' }}>
          <TYPE.mediumHeader
            style={{
              marginTop: '0rem',
              fontSize: '24px',
              letterSpacing: '1px',
              textAlign: 'center',
              paddingBottom: '0.5rem'
            }}
          >
            Participating Pools
          </TYPE.mediumHeader>
          <Countdown exactEnd={stakingInfos?.[1]?.periodFinish} />
        </DataRow>
        {/* // working section staking */}
        <PoolSection>
          {stakingRewardsExist && stakingInfos?.length === 0 ? (
            <Loader style={{ margin: 'auto' }} />
          ) : !stakingRewardsExist ? (
            'No active rewards'
          ) : (
            stakingInfos?.map(stakingInfo => {
              // need to sort by added liquidity here
              return stakingInfo.isTokenOnly ? (
                <PoolCard key={stakingInfo.stakingRewardAddress} stakingInfo={stakingInfo} />
              ) : (
                <></>
              )
            })
          )}
        </PoolSection>
        {/* till here akash */}

        <BottomCardWide>
          <AutoColumn gap="0px">
            <FixedHeightRow>
              <TYPE.titleText1
                color={'bg19'}
                fontSize={11}
                paddingTop={'0rem'}
                paddingBottom={'0rem'}
                letterSpacing={'0.5px'}
                textAlign={'left'}
              >
                OHO - Humanized Crypto for Everyone
              </TYPE.titleText1>
              <TYPE.titleText1
                color={'bg19'}
                fontSize={11}
                paddingTop={'0rem'}
                paddingBottom={'0rem'}
                letterSpacing={'0.5px'}
                textAlign={'right'}
              >
                All Rights Reserved.
              </TYPE.titleText1>
            </FixedHeightRow>
          </AutoColumn>
        </BottomCardWide>
      </AutoColumn>
    </PageWrapper>
  )
}
