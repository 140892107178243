import React, { useState } from 'react'
import Modal from '../Modal'
import { AutoColumn } from '../Column'
import styled from 'styled-components'
import { RowBlank } from '../Row'
import { TYPE, CloseIconFixed } from '../../theme'
import { ButtonError } from '../Button'
import { StakingInfo } from '../../state/stake/hooks'
import { useStakingContract } from '../../hooks/useContract'
import { SubmittedView, LoadingView } from '../ModalViews'
import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../../state/transactions/hooks'
import FormattedCurrencyAmount from '../FormattedCurrencyAmount'
import { useActiveWeb3React } from '../../hooks'
import { WithdrawCard } from '../Card'

const ContentWrapper = styled(AutoColumn)`
  position: relative;
  width: 100%;
  padding: 1rem;
`

interface StakingModalProps {
  isOpen: boolean
  onDismiss: () => void
  stakingInfo: StakingInfo
}

export default function UnstakingModal({ isOpen, onDismiss, stakingInfo }: StakingModalProps) {
  const { account } = useActiveWeb3React()

  // monitor call to help UI loading state
  const addTransaction = useTransactionAdder()
  const [hash, setHash] = useState<string | undefined>()
  const [attempting, setAttempting] = useState(false)

  function wrappedOndismiss() {
    setHash(undefined)
    setAttempting(false)
    onDismiss()
  }

  const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress)

  async function onWithdraw() {
    if (stakingContract && stakingInfo?.stakedAmount) {
      setAttempting(true)
      await stakingContract
        .exit({ gasLimit: 5000000 })
        .then((response: TransactionResponse) => {
          addTransaction(response, {
            summary: `Withdraw deposited liquidity`
          })
          setHash(response.hash)
        })
        .catch((error: any) => {
          setAttempting(false)
          //  console.log(error)
        })
    }
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }
  if (!stakingInfo?.stakedAmount) {
    error = error ?? 'Enter an amount'
  }

  // console.log('hash',hash)
  // console.log('attempting',attempting)

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOndismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBlank>
            <TYPE.mediumHeader fontSize={'24px'} textAlign={'center'}>
              Withdraw
            </TYPE.mediumHeader>
            <CloseIconFixed onClick={wrappedOndismiss} />
          </RowBlank>

          <WithdrawCard margin={'0rem 0rem 0rem 0rem'} padding={'0.75rem 1rem 1rem 1rem'} borderRadius={'1.5rem'}>
            {stakingInfo?.stakedAmount && (
              <AutoColumn justify="center" gap="0">
                <TYPE.titleText1 fontWeight={600} fontSize={36}>
                  {<FormattedCurrencyAmount currencyAmount={stakingInfo.stakedAmount} />}
                </TYPE.titleText1>
                <TYPE.titleText3>Deposited WOHO</TYPE.titleText3>
              </AutoColumn>
            )}
            {stakingInfo?.earnedAmount && (
              <AutoColumn justify="center" gap="0">
                <TYPE.titleText1 fontWeight={600} fontSize={36} paddingTop={'0.75rem'}>
                  {<FormattedCurrencyAmount currencyAmount={stakingInfo?.earnedAmount} />}
                </TYPE.titleText1>
                <TYPE.titleText3>Unclaimed WOHO</TYPE.titleText3>
              </AutoColumn>
            )}
          </WithdrawCard>
          <TYPE.subHeader style={{ textAlign: 'center' }}>
            When you withdraw, your WOHO is claimed and removed from the staking pool.
          </TYPE.subHeader>
          <TYPE.red marginTop={'0.1rem'} fontWeight={500} fontSize={14} textAlign={'center'} lineHeight={'1.0rem'}>
            A withdrawal within 24 hours of the deposit
          </TYPE.red>
          <TYPE.red marginTop={'0.0rem'} fontWeight={500} fontSize={14} textAlign={'center'} lineHeight={'0.4rem'}>
            is subject to 0.10% fee.
          </TYPE.red>
          <ButtonError disabled={!!error} error={!!error && !!stakingInfo?.stakedAmount} onClick={onWithdraw}>
            {error ?? 'Withdraw & Claim'}
          </ButtonError>
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOndismiss}>
          <AutoColumn gap="12px" justify={'center'}>
            {!stakingInfo.isTokenOnly ? (
              <TYPE.bodyText1 fontSize={20}>
                Withdrawing {stakingInfo?.stakedAmount?.toFixed(4, { groupSeparator: ',' })} WOHO
              </TYPE.bodyText1>
            ) : (
              <TYPE.bodyText1 fontSize={20}>
                Withdrawing {stakingInfo?.stakedAmount?.toFixed(4, { groupSeparator: ',' })} WOHO
              </TYPE.bodyText1>
            )}
            <TYPE.bodyText3 fontSize={20}>
              Claiming {stakingInfo?.earnedAmount?.toFixed(4, { groupSeparator: ',' })} WOHO
            </TYPE.bodyText3>
          </AutoColumn>
        </LoadingView>
      )}
      {hash && (
        <SubmittedView onDismiss={wrappedOndismiss} hash={hash}>
          <AutoColumn gap="12px" justify={'center'}>
            <TYPE.largeHeader>Transaction Submitted</TYPE.largeHeader>
            {!stakingInfo.isTokenOnly ? (
              <TYPE.bodyText1 fontSize={20}>Withdrew WOHO!</TYPE.bodyText1>
            ) : (
              <TYPE.bodyText1 fontSize={20}>Withdrew WOHO!</TYPE.bodyText1>
            )}
            <TYPE.bodyText3 fontSize={20}>Claimed WOHO!</TYPE.bodyText3>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  )
}
