import React, { useRef } from 'react'
// import { BookOpen, Code, Info, MessageCircle } from 'react-feather'
import {
  Disc,
  GitHub,
  PlusCircle,
  Wind,
  MessageCircle,
  Send,
  Twitter,
  Facebook,
  FileText,
  Shield,
  Target
} from 'react-feather'
import styled from 'styled-components'
import { ReactComponent as MenuIcon } from '../../assets/images/menu.svg'
// import { useActiveWeb3React } from '../../hooks'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleModal } from '../../state/application/hooks'

import { ExternalLink } from '../../theme'
// import { ButtonPrimary } from '../Button'

const StyledMenuIcon = styled(MenuIcon)`
  path {
    stroke: ${({ theme }) => theme.text3};
  }
`

const StyledMenuButton = styled.button`
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  height: 35px;
  background-color: ${({ theme }) => theme.bg14};
  padding: 0rem 0.35rem;
  border-radius: 2rem;

  :hover {
    background-color: ${({ theme }) => theme.bg4};
  }

  :focus {
    cursor: pointer;
    outline: none;
    background-color: ${({ theme }) => theme.bg4};
  }

  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.bg14};
    background-color: ${({ theme }) => theme.bg4};
  }

  svg {
    margin-top: 2px;
  }
`

const StyledMenu = styled.div`
  margin-left: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  text-align: left;
`

const MenuFlyout = styled.span`
  min-width: 9.525rem;
  background-color: ${({ theme }) => theme.bg9};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  border-radius: 12px;
  padding: 0.1rem;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  position: absolute;
  top: 4rem;
  right: 0rem;
  z-index: 100;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    top: -9.25rem;
  `};
`

const MenuItem = styled(ExternalLink)`
  flex: 1;
  padding: 0.5rem 0.5rem;
  color: ${({ theme }) => theme.text2};
  :hover {
    color: ${({ theme }) => theme.text5};
    cursor: pointer;
    text-decoration: none;
  }
  > svg {
    margin-right: 8px;
  }
`

// const CODE_LINK = 'https://github.com/Uniswap/uniswap-interface'

export default function Menu() {
  // const { account } = useActiveWeb3React()

  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.MENU)
  const toggle = useToggleModal(ApplicationModal.MENU)
  useOnClickOutside(node, open ? toggle : undefined)
  // const openClaimModal = useToggleModal(ApplicationModal.ADDRESS_CLAIM)

  return (
    // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451
    <StyledMenu ref={node as any}>
      <StyledMenuButton onClick={toggle}>
        <StyledMenuIcon />
      </StyledMenuButton>

      {open && (
        <MenuFlyout>
          <MenuItem id="link" href="https://oho.ai">
            <Disc size={14} />
            oho.ai
          </MenuItem>
          <MenuItem id="link" href="https://createtoken.oho.ai/">
            <PlusCircle size={14} />
            Create Token
          </MenuItem>
          <MenuItem id="link" href="https://multisender.org/">
            <Wind size={14} />
            Multisender
          </MenuItem>
          <MenuItem id="link" href="https://oho.ai/osc20-token-logo-submission/">
            <Target size={14} />
            Submit Logo
          </MenuItem>
          <MenuItem id="link" href="https://discord.gg/oho">
            <MessageCircle size={14} />
            Discord
          </MenuItem>
          <MenuItem id="link" href="https://t.me/ohonews">
            <Send size={14} />
            Telegram
          </MenuItem>
          <MenuItem id="link" href="https://twitter.com/ohoblockchain">
            <Twitter size={14} />
            Twitter
          </MenuItem>
          <MenuItem id="link" href="https://www.facebook.com/OHOBlockchain">
            <Facebook size={14} />
            FaceBook
          </MenuItem>
          <MenuItem id="link" href="https://github.com/ohonetwork">
            <GitHub size={14} />
            GitHub
          </MenuItem>
          <MenuItem id="link" href="https://oho.ai/terms-of-use">
            <FileText size={14} />
            Terms of Use
          </MenuItem>
          <MenuItem id="link" href="https://oho.ai/privacy-policy">
            <Shield size={14} />
            Privacy Policy
          </MenuItem>
        </MenuFlyout>
      )}
    </StyledMenu>
  )
}
