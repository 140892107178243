import styled from 'styled-components'
import { AutoColumn } from '../Column'
import { transparentize } from 'polished'

//import uImage from '../../assets/images/big_unicorn.png'
import xlUnicorn from '../../assets/images/xl_uni.png'
import noise from '../../assets/images/noise.png'

export const TextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 20px;
  width: fit-content;
  justify-self: flex-end;
`

export const DataCard = styled(AutoColumn) <{ disabled?: boolean }>`
//  background-color:${({ theme }) => theme.bg9};
  background-color: ${({ theme }) => transparentize(0.6, theme.bg11)};
  color:${({ theme }) => theme.text1};
  border-radius: 2.0rem;
  width: 100%;
  position: relative;
  overflow: hidden;
`
//background: url(${uImage});
export const CardBGImage = styled.span<{ desaturate?: boolean }>`
  
  width: 1000px;
  height: 600px;
  position: absolute;
  border-radius: 2.0rem;
  opacity: 0.4;
  top: -100px;
  left: -100px;
//  transform: rotate(-15deg);
  user-select: none;

  ${({ desaturate }) => desaturate && `filter: saturate(0)`}
`

export const CardBGImageSmaller = styled.span<{ desaturate?: boolean }>`
  background: url(${xlUnicorn});
  width: 1200px;
  height: 1200px;
  position: absolute;
  border-radius: 2.0rem;
  top: -300px;
  left: -300px;
  opacity: 0.4;
  user-select: none;

  ${({ desaturate }) => desaturate && `filter: saturate(0)`}
`

export const CardNoise = styled.span`
//  background: url(${noise});
  background-size: cover;
  mix-blend-mode: overlay;
  border-radius: 2.0rem;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  position: absolute;
  top: 0;
  left: 0;
  user-select: none;
`

//OHO Swap - top card Pool, Farm & Stake pages 04-07-2022
export const CardSection = styled(AutoColumn) <{ disabled?: boolean }>`
  padding: 0.5rem 1rem 0.75rem 1rem;
  border-radius: 2.0rem;
  z-index: 1;
  opacity: ${({ disabled }) => disabled && '0.4'};
//  background-color: ${({ theme }) => transparentize(0.6, theme.bg11)};
//  background-color:${({ theme }) => theme.bg21};
`

//OHO Swap - middle card on detail pages - Farm & Stake 04-08-2022
export const CardSectionMiddle = styled(AutoColumn) <{ disabled?: boolean }>`
  padding: 1rem 1rem;
  border-radius: 2.0rem;
  z-index: 2;
  background-color:${({ theme }) => theme.bg11};
`

//OHO Swap - Top card on detail pages - Farm & Stake 04-11-2022
export const CardSectionTop = styled(AutoColumn) <{ disabled?: boolean }>`
  padding: 1rem 1rem;
  justify-content: center;  
  border-radius: 2.0rem;
//  border: 2px solid ${({ theme }) => theme.bg21};
  z-index: 2;
  width: 100%;
  background-color:${({ theme }) => theme.bg11};
`



export const Break = styled.div`
  width: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  height: 1px;
`
