import React, { useCallback, useContext, useState } from 'react'
import { AutoColumn } from '../../components/Column'
import styled, { ThemeContext } from 'styled-components'
import { Link } from 'react-router-dom'

import { JSBI, TokenAmount, ETHER } from '@uniswap/sdk'
import { RouteComponentProps } from 'react-router-dom'

import CurrencyLogo from 'components/CurrencyLogo'
import { useCurrency } from '../../hooks/Tokens'
import { useWalletModalToggle } from '../../state/application/hooks'
import { TYPE } from '../../theme'

import { RowBetween, RowBlank } from '../../components/Row'
import { CardSectionTop, CardSectionMiddle, DataCard, CardNoise, CardBGImage } from '../../components/earn/styled'
import { PoolClaimCard, PoolRateCard } from '../../components/Card'
import { ButtonPrimary, ButtonPrimaryGreen, ButtonPrimaryRed } from '../../components/Button'
import StakingModal from '../../components/earn/StakingModal'
import { useStakingInfo } from '../../state/stake/hooks'
import UnstakingModal from '../../components/earn/UnstakingModal'
import ClaimRewardModal from '../../components/earn/ClaimRewardModal'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useActiveWeb3React } from '../../hooks'
import { useColor } from '../../hooks/useColor'
import { CountUp } from 'use-count-up'

import { wrappedCurrency } from '../../utils/wrappedCurrency'
import { currencyId } from '../../utils/currencyId'
import { useTotalSupply } from '../../data/TotalSupply'
import { usePair } from '../../data/Reserves'
import usePrevious from '../../hooks/usePrevious'
import useUSDCPrice from '../../utils/useUSDCPrice'
import { transparentize } from 'polished'
import { DownloadCloud } from 'react-feather'

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`

const PositionInfo = styled(AutoColumn)<{ dim: any }>`
  position: relative;
  max-width: 640px;
  width: 100%;
  opacity: ${({ dim }) => (dim ? 0.6 : 1)};
`

const BottomSection = styled(AutoColumn)`
  border-radius: 12px;
  width: 100%;
  position: relative;
`

const StyledDataCard = styled(DataCard)<{ bgColor?: any; showBackground?: any }>`
  z-index: 2;
  //  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  //  background: #314d58;
`

//OHO Swap added card styles
const StyledTopCard = styled(PoolRateCard)`
  margin-top: 0.5rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: 1.5rem;
  z-index: 1;
`

const StyledBottomCard = styled(PoolClaimCard)<{ dim: any }>`
  margin-top: 0.5rem;
  padding: 0rem 1rem 0.5rem 1rem;
  z-index: 1;
`

const PoolData = styled(DataCard)`
  background: none;
  border: 0px solid ${({ theme }) => theme.text2};
  background-color: ${({ theme }) => transparentize(0, theme.bg21)};
  padding: 0.5rem 1rem 1rem 1rem;
  z-index: 1;
`

const DataRow = styled(RowBetween)`
  justify-content: center;
  gap: 0.5rem;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    gap: 0.5rem;
  `};
`

export default function Manage({
  match: {
    params: { currencyIdA, currencyIdB, rewardsAddress },
  },
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string; rewardsAddress: string }>) {
  const { account, chainId } = useActiveWeb3React()

  // OHO Swap added icon
  const theme = useContext(ThemeContext)

  // get currencies and pair
  const [currencyA, currencyB] = [useCurrency(currencyIdA), useCurrency(currencyIdB)]
  const tokenA = wrappedCurrency(currencyA ?? undefined, chainId)
  const tokenB = wrappedCurrency(currencyB ?? undefined, chainId)

  const [, stakingTokenPair] = usePair(tokenA, tokenB)
  const stakingInfos = useStakingInfo(stakingTokenPair)
  let stakingInfo = stakingInfos?.reduce<any>((memo, staking) => {
    if (staking.stakingRewardAddress === rewardsAddress) {
      return staking
    } else {
      return memo
    }
  }, [])

  if (stakingInfo.length === 0) {
    stakingInfo = undefined
  }

  // detect existing unstaked LP position to show add button if none found
  const userLiquidityUnstaked = useTokenBalance(account ?? undefined, stakingInfo?.stakedAmount?.token)
  const showAddLiquidityButton = Boolean(stakingInfo?.stakedAmount?.equalTo('0') && userLiquidityUnstaked?.equalTo('0'))
  // toggle for staking modal and unstaking modal
  const [showStakingModal, setShowStakingModal] = useState(false)
  const [showUnstakingModal, setShowUnstakingModal] = useState(false)
  const [showClaimRewardModal, setShowClaimRewardModal] = useState(false)

  // fade cards if nothing staked or nothing earned yet
  const disableTop = !stakingInfo?.stakedAmount || stakingInfo.stakedAmount.equalTo(JSBI.BigInt(0))

  const token = currencyA === ETHER ? tokenB : tokenA
  const WETH = currencyA === ETHER ? tokenA : tokenB
  const backgroundColor = useColor(token)

  // get WETH value of staked LP tokens
  const totalSupplyOfStakingToken = useTotalSupply(stakingInfo?.stakedAmount?.token)
  let valueOfTotalStakedAmountInWETH: TokenAmount | undefined
  let valueOfMyStakedAmountInWETH: TokenAmount | undefined

  const token0 = stakingInfo?.tokens[0]
  if (totalSupplyOfStakingToken && stakingTokenPair && stakingInfo && WETH && !stakingInfo.isTokenOnly) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.totalStakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
    valueOfMyStakedAmountInWETH = new TokenAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(stakingInfo.stakedAmount.raw, stakingTokenPair.reserveOf(WETH).raw),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.raw
      )
    )
  } else if (stakingInfo?.isTokenOnly && token0) {
    valueOfTotalStakedAmountInWETH = new TokenAmount(token0, stakingInfo.totalStakedAmount.raw)
    valueOfMyStakedAmountInWETH = new TokenAmount(token0, stakingInfo.stakedAmount.raw)
  }

  const countUpAmount = stakingInfo?.earnedAmount?.toFixed(8) ?? '0'
  const countUpAmountPrevious = usePrevious(countUpAmount) ?? '0'

  // get the USD value of staked WETH
  // const token0 = stakingInfo.tokens[0]
  const USDPrice = useUSDCPrice(WETH)
  const rewardTokenPrice = useUSDCPrice(token0)
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH &&
    (stakingInfo.isTokenOnly ? rewardTokenPrice : USDPrice)?.quote(valueOfTotalStakedAmountInWETH)

  const valueOfMyStakedAmountInUSDC =
    valueOfMyStakedAmountInWETH &&
    (stakingInfo.isTokenOnly ? rewardTokenPrice : USDPrice)?.quote(valueOfMyStakedAmountInWETH)

  const toggleWalletModal = useWalletModalToggle()

  const handleDepositClick = useCallback(() => {
    if (account) {
      setShowStakingModal(true)
    } else {
      toggleWalletModal()
    }
  }, [account, toggleWalletModal])
  const currency2 = stakingInfo?.baseToken[0]


  const isStaking = Boolean(stakingInfo?.stakedAmount.greaterThan('0'))

  return (
    <PageWrapper gap="lg" justify="center">
      <Aligner>
        <CurrencyLogo currency={currencyA ?? undefined} size={'24px'} />
        <TYPE.mediumHeader paddingLeft={'0.5rem'} fontSize={'24px'} style={{ margin: 0 }}>
          <TYPE.titleText4 fontSize={24}>WOHO Staking</TYPE.titleText4>
        </TYPE.mediumHeader>
      </Aligner>

      <PoolData>
        <TYPE.titleText4
          style={{ margin: 0 }}
          paddingBottom={'0.25rem'}
          fontSize={20}
          fontWeight={500}
          textAlign={'center'}
        >
          Total Deposits
        </TYPE.titleText4>
        <TYPE.bodyText1 fontSize={20} fontWeight={500} textAlign={'center'}>
          {valueOfTotalStakedAmountInUSDC
            ? `$${valueOfTotalStakedAmountInUSDC.toFixed(4, {
                groupSeparator: ',',
              })}-${valueOfTotalStakedAmountInWETH?.toFixed(4, { groupSeparator: ',' })}WOHO `
            : `${valueOfTotalStakedAmountInWETH?.toFixed(4, { groupSeparator: ',' }) ?? '-'} WOHO`}
        </TYPE.bodyText1>
        <StyledTopCard>
          <TYPE.titleText4
            style={{ margin: 0 }}
            paddingBottom={'0.25rem'}
            fontSize={20}
            fontWeight={500}
            textAlign={'center'}
          >
            Pool Rate
          </TYPE.titleText4>
          {stakingInfo?.isTokenOnly ? (
            <TYPE.bodyText1 fontSize={20} fontWeight={500} textAlign={'center'}>
              {`${stakingInfo.totalRewardRate
                ?.multiply(stakingInfo.totalStakedAmount)
                ?.multiply(`${60 * 60 * 24 * 10 ** 4}`)
                ?.toFixed(4, { groupSeparator: ',' })} WOHO / day`}
            </TYPE.bodyText1>
          ) : (
            <TYPE.bodyText1 fontSize={20} fontWeight={500} textAlign={'center'}>
              {stakingInfo?.totalRewardRate?.multiply((60 * 60 * 24).toString())?.toFixed(4, { groupSeparator: ',' }) ??
                '-'}
              {' WOHO / day'}
            </TYPE.bodyText1>
          )}
        </StyledTopCard>
      </PoolData>
      {showAddLiquidityButton && (
        <CardSectionTop>
          <AutoColumn gap="0rem">
            <RowBlank>
              <TYPE.titleText4 textAlign={'center'} fontWeight={500} fontSize={20}>
                First, get {stakingInfo?.name && stakingInfo?.name !== '' ? stakingInfo.name : 'OHO LP'} tokens
              </TYPE.titleText4>
            </RowBlank>
            <RowBlank style={{ marginBottom: '0.25rem', marginTop: '0.25rem' }}>
              <TYPE.bodyText1 textAlign={'center'} fontSize={14}>
                {(stakingInfo?.name && stakingInfo?.name !== '' ? stakingInfo.name : 'WOHO ') +
                  ' tokens are required for deposit this tokens. Please buy the tokens in OHO swap page'}
              </TYPE.bodyText1>
            </RowBlank>
            <ButtonPrimary
              padding="8px 16px"
              borderRadius="8px"
              as={Link}
              to={`/swap/${currencyA && currencyId(currencyA)}/${currency2 && currencyId(currency2)}`}
            >
              {`Add ${currencyA?.symbol} tokens`}
            </ButtonPrimary>
          </AutoColumn>
        </CardSectionTop>
      )}

      {stakingInfo && (
        <>
          <StakingModal
            isOpen={showStakingModal}
            onDismiss={() => setShowStakingModal(false)}
            stakingInfo={stakingInfo}
            userLiquidityUnstaked={userLiquidityUnstaked}
          />
          <UnstakingModal
            isOpen={showUnstakingModal}
            onDismiss={() => setShowUnstakingModal(false)}
            stakingInfo={stakingInfo}
          />
          <ClaimRewardModal
            isOpen={showClaimRewardModal}
            onDismiss={() => setShowClaimRewardModal(false)}
            stakingInfo={stakingInfo}
          />
        </>
      )}
      <PositionInfo gap="0" justify="center" dim={showAddLiquidityButton}>
        <BottomSection gap="lg" justify="center">
          <StyledDataCard disabled={disableTop} bgColor={backgroundColor} showBackground={!showAddLiquidityButton}>
            <CardSectionMiddle>
              <AutoColumn gap="md">
                <RowBlank>
                  <TYPE.titleText4 fontSize={24} paddingLeft={'0rem'} textAlign={'center'} fontWeight={500}>
                    Your WOHO Deposits
                  </TYPE.titleText4>
                </RowBlank>

                <RowBlank>
                  <TYPE.titleText3 fontSize={24} textAlign={'center'} fontWeight={500}>
                    {valueOfMyStakedAmountInUSDC
                      ? `$${valueOfMyStakedAmountInUSDC.toFixed(2, {
                          groupSeparator: ',',
                        })}-${valueOfMyStakedAmountInWETH?.toFixed(4, { groupSeparator: ',' }) ?? '-'} WOHO`
                      : `${valueOfMyStakedAmountInWETH?.toFixed(4, { groupSeparator: ',' }) ?? '-'} WOHO`}
                  </TYPE.titleText3>
                </RowBlank>
              </AutoColumn>
              <StyledBottomCard dim={stakingInfo?.stakedAmount?.equalTo(JSBI.BigInt(0))}>
                <CardBGImage desaturate />
                <CardNoise />
                <AutoColumn gap="sm">
                  <RowBetween paddingTop={'0.25rem'}>
                    <TYPE.bodyText3 paddingTop={'0.25rem'}>Recent WOHO Profit</TYPE.bodyText3>
                    <TYPE.bodyText3 paddingTop={'0.25rem'}>Your Rate</TYPE.bodyText3>
                  </RowBetween>
                  <RowBetween style={{ alignItems: 'baseline' }}>
                    <TYPE.largeHeader fontSize={36} fontWeight={500}>
                      {Number(countUpAmount) > 0 ? (
                        <CountUp
                          key={countUpAmount}
                          isCounting
                          decimalPlaces={8}
                          start={parseFloat(countUpAmountPrevious)}
                          end={parseFloat(countUpAmount)}
                          thousandsSeparator={','}
                          duration={1}
                        />
                      ) : (
                        <CountUp
                          key={0}
                          isCounting
                          decimalPlaces={0}
                          start={parseFloat('0')}
                          end={parseFloat('0')}
                          thousandsSeparator={','}
                          duration={1}
                        />
                      )}
                    </TYPE.largeHeader>
                    {isStaking ? (
                      !stakingInfo?.ended && (
                        <TYPE.titleText1 fontSize={18} fontWeight={500}>
                          <span role="img" aria-label="wizard-icon" style={{ marginRight: '0px ' }}>
                            ⚡
                          </span>
                          {stakingInfo.totalRewardRate
                            ?.multiply(stakingInfo.stakedAmount)
                            ?.multiply((60 * 60 * 24 * 10 ** 4).toString())
                            ?.toFixed(4, { groupSeparator: ',' }) ?? '-'}{' '}
                          WOHO / DAY
                        </TYPE.titleText1>
                      )
                    ) : (
                      <></>
                    )}
                  </RowBetween>
                </AutoColumn>
              </StyledBottomCard>
            </CardSectionMiddle>
          </StyledDataCard>
        </BottomSection>

        <TYPE.titleText3 marginTop={'0.5rem'} fontWeight={500} fontSize={14} textAlign={'center'} lineHeight={'1.0rem'}>
          When you withdraw <DownloadCloud size="16" color={theme.secondary2} /> , the contract will automatically claim
          WOHO on your behalf.
        </TYPE.titleText3>

        <TYPE.darkGray marginTop={'0.1rem'} fontWeight={500} fontSize={14} textAlign={'center'} lineHeight={'1.0rem'}>
          A withdrawal within 24 hours of the deposit is subject to 0.10% fee.
        </TYPE.darkGray>

        {!showAddLiquidityButton && (
          <DataRow style={{ marginBottom: '0.5rem', marginTop: '0.25rem' }}>
            {!stakingInfo?.ended && (
              <ButtonPrimaryGreen padding="8px" borderRadius="2rem" onClick={handleDepositClick}>
                {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0))
                  ? 'Deposit'
                  : stakingInfo && stakingInfo?.name !== ''
                  ? stakingInfo?.isNftToken
                    ? 'Deposit NFT Tokens'
                    : 'Deposit ' + stakingInfo?.name + ' Tokens'
                  : 'Deposit WOHO  Tokens'}
              </ButtonPrimaryGreen>
            )}

            {stakingInfo?.stakedAmount?.greaterThan(JSBI.BigInt(0)) && (
              <>
                <ButtonPrimaryRed padding="8px" borderRadius="8px" onClick={() => setShowUnstakingModal(true)}>
                  Withdraw
                </ButtonPrimaryRed>
              </>
            )}
          </DataRow>
        )}
        {!userLiquidityUnstaked ? null : userLiquidityUnstaked.equalTo('0') ? null : (
          <TYPE.main>
            {userLiquidityUnstaked.toFixed(4, { groupSeparator: ',' })}{' '}
            {stakingInfo?.name !== '' ? stakingInfo?.name : 'WOHO '} tokens available
          </TYPE.main>
        )}
      </PositionInfo>
    </PageWrapper>
  )
}
