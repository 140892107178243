import React from 'react'
import styled from 'styled-components'
import { darken, lighten } from 'polished'

import { RowBetween } from '../Row'
import { ChevronDown } from 'react-feather'
import { Button as RebassButton, ButtonProps } from 'rebass/styled-components'

const Base = styled(RebassButton) <{
  padding?: string
  width?: string
  borderRadius?: string
  altDisabledStyle?: boolean
}>`
  padding: ${({ padding }) => (padding ? padding : '8px')};
  width: ${({ width }) => (width ? width : '100%')};
  font-weight: 500;
  text-align: center;
  letter-spacing: 1px;
  border-radius: 20px;
  margin-top: 0px;
  // margin-right:auto; OHO Swap removed margin
  // border-radius: ${({ borderRadius }) => borderRadius && borderRadius};
  outline: none;
  // border: 1px solid transparent;
  color: white;
  text-decoration: none;
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;
  z-index: 1;
  &:disabled {
    cursor: auto;
  }

  > * {
    user-select: none;
  }
`


export const ButtonBase = styled(Base)`
    cursor: auto;
    margin-top: 6px;
    box-shadow: none;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '1')};
`

const StyledChevronDown = styled(ChevronDown)`
  stroke: ${({ theme }) => theme.bg14};

  :hover {
  stroke: ${({ theme }) => theme.bg4};
  }

  :focus {
  stroke: ${({ theme }) => theme.bg4};
  }
  
  :active {
  transform: scale(1.2,1.2);

`  


export const ButtonPrimary = styled(Base)`
  margin-top: 6px;
  color: white;
  background-color: ${({ theme }) => theme.bg14};
  &:hover {
    background-color: ${({ theme }) => theme.bg4};
  }
  &:focus {
  //  box-shadow: 0 0 0 1pt ${({ theme }) => theme.bg4};
    background-color: ${({ theme }) => theme.bg14};
  }
  
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.bg14};
    background-color: ${({ theme }) => theme.bg4};
  //  border: 1px ${({ theme }) => theme.bg14};
  }
  
  &:disabled {
    background-color: ${({ theme, altDisabledStyle, disabled }) =>
    altDisabledStyle ? (disabled ? theme.bg22 : theme.bg14) : theme.bg22};
    color: ${({ theme, altDisabledStyle, disabled }) =>
    altDisabledStyle ? (disabled ? theme.text3 : 'white') : theme.text3};
    cursor: auto;
    box-shadow: none;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.8' : '1')};
  }
`

export const ButtonPrimaryTight = styled(Base)`
  background-color: ${({ theme }) => theme.bg14};
  margin-top: 0px;
  color: white;
  background-color: ${({ theme }) => theme.bg14};
  &:hover {
    background-color: ${({ theme }) => theme.bg4};
  }
  &:focus {
  //  box-shadow: 0 0 0 1pt ${({ theme }) => theme.bg4};
    background-color: ${({ theme }) => theme.bg14};
  }

  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.bg14};
    background-color: ${({ theme }) => theme.bg4};
  //  border: 1px ${({ theme }) => theme.bg14};
  }
  &:disabled {
    background-color: ${({ theme, altDisabledStyle, disabled }) =>
    altDisabledStyle ? (disabled ? theme.bg22 : theme.bg14) : theme.bg22};
    color: ${({ theme, altDisabledStyle, disabled }) =>
    altDisabledStyle ? (disabled ? theme.text3 : 'white') : theme.text3};
    cursor: auto;
    box-shadow: none;
  //  border: 1px solid transparent;
  outline: none;
  opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.8' : '1')};
}
`

//OHO Swap added green and red buttons 04-08-2022
export const ButtonPrimaryGreen = styled(Base)`
  background-color: ${({ theme }) => theme.bg23};
  margin-top: 6px;
  color: white;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.green1)};
    background-color: ${({ theme }) => darken(0.05, theme.green1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.green1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.green2};
    background-color: ${({ theme }) => darken(0.1, theme.green1)};
  }
  &:disabled {
    background-color: ${({ theme, altDisabledStyle, disabled }) =>
    altDisabledStyle ? (disabled ? theme.bg23 : theme.green2) : theme.green1};
    color: ${({ theme, altDisabledStyle, disabled }) =>
    altDisabledStyle ? (disabled ? theme.text3 : 'white') : theme.text3};
    cursor: auto;
    box-shadow: none;
//  border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '1')};
  }
`

export const ButtonPrimaryRed = styled(Base)`
  background-color: ${({ theme }) => theme.red1};
  margin-top: 6px;
  color: white;
  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.10, theme.red1)};
    background-color: ${({ theme }) => darken(0.10, theme.red1)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.10, theme.red1)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.red2};
    background-color: ${({ theme }) => darken(0.10, theme.red1)};
  }
  &:disabled {
    background-color: ${({ theme, altDisabledStyle, disabled }) =>
    altDisabledStyle ? (disabled ? theme.bg10 : theme.red2) : theme.red1};
    color: ${({ theme, altDisabledStyle, disabled }) =>
    altDisabledStyle ? (disabled ? theme.text3 : 'white') : theme.text3};
    cursor: auto;
    box-shadow: none;
//  border: 1px solid transparent;
    outline: none;
    opacity: ${({ altDisabledStyle }) => (altDisabledStyle ? '0.5' : '1')};
  }
`



export const ButtonLight = styled(Base)`
  margin-top: 6px;
  background-color: ${({ theme }) => theme.bg14};
      color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  &:focus {
    background-color: ${({ theme }) => theme.bg4};
    border: 0px solid ${({ theme }) => theme.bg4};
    color: ${({ theme }) => theme.text3};
  }
  &:hover {
    background-color: ${({ theme }) => theme.bg4};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg14)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  :disabled {
    opacity: 0.8;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.bg4};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`

export const ButtonNetwork = styled(Base)`
  background-color: ${({ theme }) => theme.bg14};
      color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
  &:focus {
    background-color: ${({ theme }) => theme.bg4};
  //border: 0px solid ${({ theme }) => theme.bg2};
    color: ${({ theme }) => theme.text3};
  }
  &:hover {
    background-color: ${({ theme }) => theme.bg4};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg14)};
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  :disabled {
    opacity: 0.8;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.bg30};
      box-shadow: none;
      border: 1px solid transparent;
      outline: none;
    }
  }
`



export const ButtonGray = styled(Base)`
  background-color: ${({ theme }) => theme.bg10};
  color: ${({ theme }) => theme.text2};
  font-size: 16px;
  font-weight: 500;
  &:focus {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  &:hover {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.05, theme.bg4)};
  }
  &:active {
    background-color: ${({ theme, disabled }) => !disabled && darken(0.1, theme.bg4)};
  }
`

//OHO Swap 04-17-2022
export const ButtonSecondary = styled(Base)`
  border: none;
  color: ${({ theme }) => theme.text3};
  //background-color: transparent;
  background-color: ${({ theme }) => theme.bg14};
  font-size: 16px;
  border-radius: 1rem;
  padding: ${({ padding }) => (padding ? padding : '8px')};

  &:focus {
  //box-shadow: 0 0 0 1pt ${({ theme }) => theme.bg4};
    background-color: ${({ theme }) => theme.bg14};
    //border: 1px ${({ theme }) => theme.bg4};
  }
  &:hover {
    //border: 1px ${({ theme }) => theme.bg4};
    background-color: ${({ theme }) => theme.bg4};
      }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.bg14};
    background-color: ${({ theme }) => theme.bg4};
    //border: 1px ${({ theme }) => theme.bg14};
  }
  &:disabled {
    opacity: 80%;
    cursor: auto;
  }
  a:hover {
    text-decoration: none;
  }
`

export const ButtonPink = styled(Base)`
  background-color: ${({ theme }) => theme.primary1};
  color: white;

  &:focus {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.primary1)};
    background-color: ${({ theme }) => darken(0.05, theme.bg4)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.bg4)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.primary1)};
    background-color: ${({ theme }) => darken(0.1, theme.bg14)};
  }
  &:disabled {
    background-color: ${({ theme }) => theme.bg14};
    opacity: 80%;
    cursor: auto;
  }
`

export const ButtonUNIGradient = styled(ButtonPrimary)`
  color: white;
  padding: 4px 8px;
  height: 36px;
  font-weight: 500;
  background-color: ${({ theme }) => theme.red2};
  width: fit-content;
  position: relative;
  cursor: pointer;
  border: none;
  white-space: no-wrap;
  :hover {
    //opacity: 0.8;
    background-color: ${({ theme }) => theme.red3};
  }
  :active {
    //box-shadow: 0 0 0 1pt ${({ theme }) =>  theme.red2};
    opacity: 0.9;
  }
`

export const ButtonOutlined = styled(Base)`
  border: 0px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => theme.bg1};
  color: ${({ theme }) => theme.text1};

  &:focus {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.bg17};
  }
  &:hover {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.bg17};
  }
  &:active {
    box-shadow: 0 0 0 2px ${({ theme }) => theme.bg7};
  }
  &:disabled {
    opacity: 80%;
    cursor: auto;
  }
`

export const ButtonEmpty = styled(Base)`
  background-color: transparent;
  color: ${({ theme }) => theme.primary1};
  display: flex;
  justify-content: center;
  align-items: center;

  &:focus {
    text-decoration: underline;
    opacity: 75%;
  }
  &:hover {
    text-decoration: none;
    opacity: 75%;
  }
  &:active {
    text-decoration: none;
  }
  &:disabled {
    opacity: 80%;
    cursor: auto;
  }
`

export const ButtonWhite = styled(Base)`
  border: 1px solid ${({ theme }) => theme.bg14};
  background-color: ${({ theme }) => theme.bg14};
  color: ${({ theme }) => theme.text3};

  &:focus {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    box-shadow: 0 0 0 1pt ${darken(0.05,'#314d58')};
  }
  &:hover {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#314d58')};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${darken(0.1, '#314d58')};
  }
  &:disabled {
    opacity: 80%;
    cursor: auto;
  }
`

const ButtonConfirmedStyle = styled(Base)`
  margin-top: 6px;
  background-color: ${({ theme }) => lighten(0.5, theme.green1)};
  color: ${({ theme }) => theme.green1};
  border: 0px solid ${({ theme }) => theme.green1};

  &:hover {
    background-color: ${({ theme }) => darken(0.05, theme.green2)};
    color: ${({ theme }) => theme.text3};
  }

  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => theme.green1};
    background-color: ${({ theme }) => darken(0.1, theme.green2)};

  &:disabled {
    background-color: transparent;
    color: ${({ theme }) => lighten(0.0, theme.green1)};
    //border: 1px solid ${({ theme }) => lighten(0.0, theme.green1)};
    opacity: 100%;
    cursor: auto;
  }
`

const ButtonErrorStyle = styled(Base)`
  margin-top: 6px;
  background-color: ${({ theme }) => theme.red2};
  //border: 1px solid ${({ theme }) => theme.red2};
  padding: ${({ padding }) => (padding ? padding : '8px')};

  &:focus {
  //  box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.05, theme.bg10)};
    background-color: ${({ theme }) => darken(0.1, theme.red3)};
  }
  &:hover {
    background-color: ${({ theme }) => darken(0.1, theme.red3)};
  }
  &:active {
    box-shadow: 0 0 0 1pt ${({ theme }) => darken(0.1, theme.red2)};
    background-color: ${({ theme }) => darken(0.1, theme.red3)};
  }
  &:disabled {
    opacity: 80%;
    cursor: auto;
    box-shadow: none;
    background-color: ${({ theme }) => theme.bg10};
    border: 1px solid ${({ theme }) => theme.bg10};
  }
`

export function ButtonConfirmed({
  confirmed,
  altDisabledStyle,
  ...rest
}: { confirmed?: boolean; altDisabledStyle?: boolean } & ButtonProps) {
  if (confirmed) {
    return <ButtonConfirmedStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} altDisabledStyle={altDisabledStyle} />
  }
}

export function ButtonError({ error, ...rest }: { error?: boolean } & ButtonProps) {
  if (error) {
    return <ButtonErrorStyle {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}

export function ButtonDropdown({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonPrimary {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <StyledChevronDown size={24} />
      </RowBetween>
    </ButtonPrimary>
  )
}

export function ButtonDropdownGrey({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonGray {...rest} disabled={disabled} style={{ borderRadius: '20px' }}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <StyledChevronDown size={24} />
      </RowBetween>
    </ButtonGray>
  )
}

export function ButtonDropdownLight({ disabled = false, children, ...rest }: { disabled?: boolean } & ButtonProps) {
  return (
    <ButtonOutlined {...rest} disabled={disabled}>
      <RowBetween>
        <div style={{ display: 'flex', alignItems: 'center' }}>{children}</div>
        <StyledChevronDown size={24} />
      </RowBetween>
    </ButtonOutlined>
  )
}

export function ButtonRadio({ active, ...rest }: { active?: boolean } & ButtonProps) {
  if (!active) {
    return <ButtonWhite {...rest} />
  } else {
    return <ButtonPrimary {...rest} />
  }
}
