import { Text } from 'rebass'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  padding: 0.5rem 1rem 1rem 1rem;
`

export const PlusWrapper = styled.div`
  padding: 4px;
  border-radius: 1.5rem;
  height: 36px;
  width: 36px;
  position: relative;
  margin-top: -15px;
  margin-bottom: -15px;
  background-color: ${({ theme }) => theme.bg11};
  border: 0px solid ${({ theme }) => theme.bg11};
  z-index: 100;
`

//OHO Swap add hover color 04-08-2022 
export const ClickableText = styled(Text)`
  color: ${({ theme }) => theme.bg14};
  :hover {
    cursor: pointer;
    color: ${({ theme }) => theme.bg4};
  }

`

export const MaxButton = styled.button<{ width: string }>`
  padding: 0.25rem 1rem;
  background-color: ${({ theme }) => theme.bg14};
  border: 1px solid transparent;
  border-radius: 2rem;
  font-size: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  font-weight: 500;
  cursor: pointer;
  margin: 0.25rem;
  overflow: hidden;
  color: ${({ theme }) => theme.bg26};
  :hover {
    border: 1px solid ${({ theme }) => theme.bg4};
    background-color: ${({ theme }) => theme.bg4};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.bg4};
    outline: none;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
