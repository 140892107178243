import { transparentize } from 'polished'
import React, { useMemo } from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme
} from 'styled-components'
import { useIsDarkMode } from '../state/user/hooks'
import { Text, TextProps } from 'rebass'
import { Colors } from './styled'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ; (accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

//03-04-2022 OHO Swap color theme

const white = '#FFFFFF'
const black = '#020a13'

export function colors(darkMode: boolean): Colors {
    return {

    // base
    white,
    black,

    // text
    text1: darkMode ? '#FFFFFF' : '#314d58',
    text2: darkMode ? '#16aab8' : '#0d8ca5',
    text3: darkMode ? '#e7e7e7' : '#FFFFFF',
    text4: darkMode ? '#F2F2F2' : '#a6a6a6',
    text5: darkMode ? '#0d8ca5' : '#24d5d3',

    text6: darkMode ? '#e7e7e7' : '#273e46',
    text7: darkMode ? '#d4af37' : '#d4af37',
    text8: darkMode ? '#FFFFFF' : '#314d58',
    text9: darkMode ? '#FFFFFF' : '#0d8ca5',
    text10: darkMode ? '#24d5d3' : '#314d58',

    text11: darkMode ? '#e7e7e7' : '#535353',
    text12: darkMode ? '#d4af37' : '#b29700',
    text13: darkMode ? '#24d5d3' : '#1a6575',
    text14: darkMode ? '#FFFFFF' : '#071320',
    text15: darkMode ? '#24d5d3' : '#29f8f6',

    text16: darkMode ? '#F2F2F2' : '#314d58',


    // backgrounds 

    bg1: darkMode ? '#314d58' : '#e7e7e7',
    bg2: darkMode ? '#14768a' : '#0d8ca5',
    bg3: darkMode ? '#071320' : '#314d58',
    bg4: darkMode ? '#b29700' : '#b29700',
    bg5: darkMode ? '#e7e7e7' : '#FFFFFF',

    bg6: darkMode ? '#0c2d3c' : '#eee1d8',
    bg7: darkMode ? '#273e46' : '#c5d0d6',
    bg8: darkMode ? '#0d2d3c' : '#dbdbdb',
    bg9: darkMode ? '#071320' : '#ffffff',
    bg10: darkMode ? '#c23a22' : '#e54c38',

    bg11: darkMode ? '#071320' : '#ffffff',
    bg12: darkMode ? '#0f2135' : '#e7e7e7',
    bg13: darkMode ? '#b29700' : '#d4af37',
    bg14: darkMode ? '#d4af37' : '#d4af37',
    bg15: darkMode ? 'rgba(20, 118, 138, 0.25)' : 'rgba(13, 140, 165, 0.25)',

    bg16: darkMode ? '#24d5d3' : '#0d8ca5',
    bg17: darkMode ? '#14768a' : '#c9c9c9',
    bg18: darkMode ? '#0d8ca5' : '#d4af37',
    bg19: darkMode ? '#14768a' : '#ffffff',
    bg20: darkMode ? '#071320' : '#e7e7e7',

    bg21: darkMode ? '#0d2d3c' : '#f2f2f2',
    bg22: darkMode ? 'rgba(178, 151, 0, 0.50)' : 'rgba(212, 175, 55, 0.50)',
    bg23: darkMode ? '#7daa6a' : '#438029',
    bg24: darkMode ? '#FFFFFF' : '#f2f2f2',
    bg25: darkMode ? '#0d8ca5' : '#16aab8',

    bg26: darkMode ? '#314d58' : '#ffffff',
    bg27: darkMode ? '#0d2d3c' : '#ffffff',
    bg28: darkMode ? '#f2f2f2' : '#e7e7e7',
    bg29: darkMode ? '#0d2d3c' : '#e7e7e7',
    bg30: darkMode ? '#0d2d3c' : '#14768a',

    bg31: darkMode ? '#535353' : '#a6a6a6',

    //specialty colors
    modalBG: darkMode ? 'rgba(49,77,88,0.75)' : 'rgba(49,77,88,0.75)', 
    advancedBG: darkMode ? 'rgba(49,77,88,0.55)' : 'rgba(49,77,88,0.55)',

    //primary colors

    primary1: darkMode ? '#b29705' : '#d4af37',
    primary2: darkMode ? '#0d8ca5' : '#16aab8',
    primary3: darkMode ? '#1a6575' : '#147685',
    primary4: darkMode ? '#1cc1ca' : '#16aab8',
    primary5: darkMode ? '#0d8ca5' : '#0f9db9',

    // color text
    primaryText1: darkMode ? '#e7e7e7' : '#FFFFFF',

    // secondary colors
    secondary1: darkMode ? '#16aab8' : '#117f95',
    secondary2: darkMode ? '#ed8c79' : '#ec6572',
    secondary3: darkMode ? '#0d2d3d' : '#619a46',
   
    // other
    red1: darkMode ? '#e54c38' : '#c23a22',
    red2: darkMode ? '#c23a22' : '#e54c38',
    red3: darkMode ? '#a81800' : '#c23a22',
    green1: darkMode ? '#619a46' : '#438029',
    green2: darkMode ? '#7daa6a' : '#438029',
    yellow1: '#e1c158',
    yellow2: '#e2d05b',
    blue1: darkMode ? '#14768a' : '#0d8ca5',
    blue2: darkMode ? '#537eb3' : '#537eb3',

    // dont wanna forget these blue yet
    // blue4: darkMode ? '#153d6f70' : '#C4D9F8',
    // blue5: darkMode ? '#153d6f70' : '#EBF4FF',
  }
}

export function theme(darkMode: boolean): DefaultTheme {
  return {
    ...colors(darkMode),

    grids: {
      sm: 8,
      md: 12,
      lg: 24
    },

    //shadows
    shadow1: darkMode ? '#000' : '#314d58',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const darkMode = useIsDarkMode()

  const themeObject = useMemo(() => theme(darkMode), [darkMode])

  return <StyledComponentsThemeProvider theme={themeObject}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text) <{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg25'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text8'} letterSpacing ={'1px'} margin={'auto'} fontSize={'28px'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },

//OHO gold text
  gold(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg14'} {...props} />
  },

//OHO red text
  red(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg10'} {...props} />
  },

//OHO green text
  green(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'green2'} {...props} />
  },

//OHO pink text
  pink(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'secondary2'} {...props} />
  },


//OHO light text
  light(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },

//OHO dark text
  dark(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg11'} {...props} />
  },

  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text11'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },

//OHO Swap blue text 2
  bodyText1(props: TextProps) {
    return <TextWrapper color={'text2'} {...props} />
  },

//OHO Swap blue text 2 reverse
  bodyText2(props: TextProps) {
    return <TextWrapper color={'bg2'} {...props} />
  },

//OHO Swap blue alternate
  bodyText3(props: TextProps) {
    return <TextWrapper color={'text13'} {...props} />
  },

//OHO Swap light grey
  bodyText4(props: TextProps) {
    return <TextWrapper color={'text3'} {...props} />
  },

//OHO Swap blue text 3
  bodyText5(props: TextProps) {
    return <TextWrapper color={'bg25'} {...props} />
  },

//OHO Swap dark grey
bodyText4grey(props: TextProps) {
  return <TextWrapper color={'text4'} {...props} />
},  

//OHO Swap black-dark blue and white
  titleText1(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text14'} {...props} />
  },

//OHO Swap
  titleText2(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text6'} {...props} />
  },

//OHO Swap blue 
  titleText3(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },

//OHO Swap darkblue white
  titleText4(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },

//OHO Swap - bright blue 04-08-2022
  titleText5(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text15'} {...props} />
  },

//OHO Swap - light blue
  titleText6(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text13'} {...props} />
  },

//OHO Swap - Pool Share title 04-15-2022
  titleText7(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text10'} {...props} />
  },

//OHO Swap - text white blue 04-15-2022
  titleText8(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text9'} {...props} />
  },

//OHO Swap - text grey-darkblue 04-16-2022
  titleText9(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text16'} {...props} />
  },

//OHO Swap - countdown text 05-01-2022
  titleText14(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg16'} {...props} />
  },




  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  }
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {

  font-display: fallback;
  @font-face{
    font-family:outfit;
    src: url('/fonts/Outfit-VariableFont_wght.ttf')format('woff');
  }
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family:outfit;
    src: url('/fonts/Outfit-VariableFont_wght.ttf')format('woff');
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

 a {
   color: ${colors(false).blue1}; 
 }

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: #0d8ca5;
  font-feature-settings: 'ss02' on, 'cv01' on, 'cv03' on;
  
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  letter-spacing: 0.5px;
  // background:linear-gradient(0deg,#eee1d8,#c5d0d6);
  background: linear-gradient(0deg, ${({ theme }) => theme.bg6}, ${({ theme }) => theme.bg7});
  background-size: cover;
}

body {
  min-height: 100vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;
  background-image: ${({ theme }) =>
    `radial-gradient(50% 50% at 50% 50%, ${transparentize(0.9)} 0%, ${transparentize(
      1,
      theme.bg1
    )} 100%)`};
}
`
